<template>
  <div class="main-view">
    <div class="section">
      <div class="filter__wrap">
        <el-form ref="elFormDom" inline :model="table.params" size="small">
          <el-form-item prop="name">
            <el-input v-model.trim="table.params.name" placeholder="学校名称">
              <i slot="suffix" class="el-input__icon el-icon-search" @click="onSearch"></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="nature">
            <el-select v-model="table.params.nature" placeholder="学校属性" @change="onSearch">
              <el-option label="公办" :value="1"></el-option>
              <el-option label="民办" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="type">
            <el-select v-model="table.params.type" placeholder="学校类型" @change="onSearch">
              <el-option v-for="item in schoolType" :key="item.id" :label="item.value" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </div>
    
    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:nature="{row}">
        <span>{{row.nature == 1 ? '公办' : '民办'}}</span>
      </template>
    </VTable>

    
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js'
export default {
  name: 'SchoolList',
  components: {
    VTable,
  },
  mixins: [mixinTable],
  data() {
    return {
      field: [
        { name: "name", label: "学校名称", showTooltip:true, hidden: false },
        { name: "nature", label: "学校属性", hidden: false },
        { name: "type_str", label: "学校类型", hidden: false},
        { name: "legal", label: "校长姓名", hidden: false},
        { name: "contact_person", label: "负责人", hidden: false},
        { name: "contact_phone", label: "联系电话", hidden: false},
        { name: "class_num", label: "班级数量", hidden: false},
        { name: "student_num", label: "学生人数", hidden: false},
        { name: "create_time", label: "创建时间",width:'160', hidden: false},
      ],
      table: {
        loading: false,
        params: {
          name: '',
          nature: '',
          type: '',
          page: 1, 
          count: 10
        },
        data: [],
        total: 0,
      },
      schoolType: [],
    }
  },
  created() {
    this.getTable();
    this.getType();
  },
  methods: {
    // 查询列表
    getTable() {
      this.$http.get('/government/school/list', {params: this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 获取学校类型
    getType() {
      this.$http.get('/government/school/type', {params: {}}).then(res => {
        if(res.code === 1) {
          this.schoolType = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
  }
}
</script>
